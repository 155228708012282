/**
 * loads the sharethrough script
 */
(() => {
	const sct = document.createElement('script');
	const sctHl = document.getElementsByTagName('script')[0];
	const isSSL = document.location.protocol === 'https:';
	sct.type = 'text/javascript';
	sct.src = `http${isSSL ? 's' : ''}://sdk.sharethrough.com/gc.js`;
	sct.async = 'async';
	sct.setAttribute('data-str-disable-tracking', 'true');
	sctHl.parentNode.insertBefore(sct, sctHl);
})();
