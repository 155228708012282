import './shared/serviceWorker';
import '@forbes/fbs-embedly';
import '@forbes/fbs-ticker';

import { FbsPricingInit } from '@forbes/fbs-pricing';
import { FbsCordial } from '@forbes/fbs-cordial/dist/fbsCordial';

import { serverData } from './shared/clientConfigService';

import './shared/universal-header';
import './shared/pushlyPrompt';
import { setupCordialService } from './shared/cordialData';
import newsletterOffers from './shared/newsletterOffer';
// import './shared/tableOfContentsEvents';
import './shared/subnav';
import './shared/openWebSSO';
import './shared/openWebTracking';
import './shared/openWebUtils';
import './shared/following-tooltip';

import './article/shared/cookies';
import './article/mobile/consent';
import './article/mobile/premiumBadge';
import './article/shared/ntvContentAd';
import './article/mobile/sharethrough';
import './article/mobile/adsService';
import './article/mobile/streamService';
import './article/shared/seoRecirc';
import './article/shared/marketPlaceRecirc';
import './article/shared/storyPackage';

import './article/shared/truncateContribBiosForArticle';

FbsPricingInit(serverData.isProd);
setupCordialService('article');
if (!customElements.get('fbs-cordial')) {
	customElements.define('fbs-cordial', FbsCordial);
}
newsletterOffers('.article-newsletter', 'article');
