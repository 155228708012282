// This module is loaded on AMPPlus (mobile) articles only and is used to keep AMP consent cookies in sync with trustarc cookies, so that
// we can use data-block-on-consent with amp components on mobile article, although mobile uses trustarc for consent management.
import { isEurope, isChina } from '../../shared/clientConfigService';

/**
 * One time setting of AMP consent cookies per page load.
 * @param {String} [action='reject']
 */
function setAmpConsentCookies(action = 'reject') {
	if (!document.querySelector('amp-consent')) {
		return;
	}

	customElements.whenDefined('amp-consent').then(() => {
		document.querySelector('amp-consent').whenBuilt().then(() => {
			// Need to wait for the prompt UI to be shown
			// eslint-disable-next-line max-len
			// https://github.com/ampproject/amphtml/blob/a13a851290d56d504b50f252e406fb45c66f2ffa/extensions/amp-consent/0.1/consent-ui.js#L182
			let attempts = 0;
			const id = setInterval(() => {
				// eslint-disable-next-line max-len
				// This is a temporary hack until Trustarc can provide us with a https://amp.dev/documentation/components/amp-consent/#response endpoint,
				// with which to sync our Trustarc and amp consent.
				if (document.querySelector('amp-consent')?.classList.contains('amp-active')) {
					document.querySelector(`#amp-plus-consent #${action}-consent`).click();
					clearInterval(id);
				} else if (attempts > 10) {
					clearInterval(id);
				} else {
					attempts++;
				}
			}, 200);
		});
	});
}

document.addEventListener('DOMContentLoaded', () => {
	if (isEurope || isChina) {
		if (!document.cookie.match(/notice_gdpr_prefs=/)) {
			const submitPreferencesHandler = (message) => {
				try {
					const result = JSON.parse(message.data);
					if (result && result.source === 'preference_manager' && result.message === 'submit_preferences') {
						setTimeout(() => {
							const setting = !document.cookie.match(/notice_gdpr_prefs=0,1,2/) ? 'reject' : 'accept';
							setAmpConsentCookies(setting);
							message.currentTarget.removeEventListener(message.type, submitPreferencesHandler);
						});
					}
				// eslint-disable-next-line no-empty
				} catch (e) {}
			};
			window.addEventListener('message', submitPreferencesHandler);
		}
	}
});
