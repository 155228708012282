import Event from '../../shared/event';
import { handleVideo } from './stickyVideo';
import {
	checkArticleScrollDepth,
	findArticleIndexByPath,
	getArticleTitle,
	getFirstAuthorName,
	initFusePostMessage,
	insertArticle,
	setTitle,
} from './streamUtilities';
import throttle from '../../shared/throttle';
import { createCookie } from '../../../../shared/cookieUtils';
import toplineAnimation from '../shared/toplineAnimation';
import scrollAnimation from '../shared/scrollAnimation';
import truncateContribBiosForArticle from '../shared/truncateContribBiosForArticle';

// Expires in One Week
const expiresOneWeek = new Date((+new Date()) + (1000 * 60 * 60 * 24 * 7)).toUTCString();
document.cookie = createCookie('forbes_article', 'B', expiresOneWeek);

const streamState = {
	articles: [],
	currentArticleIndex: 0,
	ntvContentLineItemId: '',
	header: document.querySelector('.header'),
	stickyAd: document.querySelector('fbs-ad[position="mobile"]'),
	bucket: window.forbes['simple-site'].bucket || 'A',
};

/**
 * Update mobile sticky video player's ad-unit-path (GAMZone).
 */
function updatePreroll() {
	Array.from(document.querySelectorAll('.amp-player')).forEach((player) => {
		const fbsVideo = player.querySelector('fbs-video');
		const brightcove = player.querySelector('amp-brightcove');

		if (fbsVideo) {
			/* eslint-disable no-underscore-dangle */
			fbsVideo.setAttribute('ad-unit-path', window.fbsads._config.ad_unit_path);
		}

		if (brightcove) {
			/* eslint-disable no-underscore-dangle */
			brightcove.setAttribute('data-param-site-zone', window.fbsads._config.ad_unit_path);
		}
	});
}

insertArticle(streamState.articles, {
	article: {
		naturalId: ((window.forbes['simple-site'] || {}).tracking || {}).naturalID,
		templateType: ((window.forbes['simple-site'] || {}).tracking || {}).templateType,
		title: document.title,
		uri: `https://www.forbes.com${window.location.pathname}`,
		authorGroup: {
			primaryAuthor: {
				name: getFirstAuthorName(),
			},
		},
	},
	headerLegaleseData: {
		correctionsMailLink: `mailto:corrections@forbes.com?subject=Report Correction${window.forbes['simple-site'].tracking.author ? `: ${window.forbes['simple-site'].tracking.author}` : ''}&body=Reporting Correction for: %0A %0A Title: ${window.forbes['simple-site'].tracking.title ? `${window.forbes['simple-site'].tracking.title} %0A ` : '%0A '}${window.forbes['simple-site'].tracking.author ? `Author: ${window.forbes['simple-site'].tracking.author} %0A ` : ''}URL: ${window.location.pathname ? `https://www.forbes.com${window.location.pathname}` : ''}%0A %0A - %0A %0A Your Name: %0A Correction Request: %0A %0A - %0A %0A Thank you for reporting a correction. Forbes Staff will review your concern shortly.`,
	},
	serverData: window.forbes['simple-site'],
}, 0);

const serverData = window.forbes['simple-site'] || {};
truncateContribBiosForArticle(0, serverData.isPremiumTemplateType);

updatePreroll();
initFusePostMessage(window.fuse);
checkArticleScrollDepth(streamState);

document.addEventListener('DOMContentLoaded', () => {
	window.history.scrollRestoration = 'manual';

	Event.addEventListener('popstate', () => {
		const article = findArticleIndexByPath(streamState, window.location.pathname);
		if (article.index !== streamState.currentArticleIndex) {
			streamState.currentArticleIndex = article.index;
			window.scrollTo(0, article.element.offsetTop);
		}
		const articleTitle = getArticleTitle(article.article);
		setTitle(articleTitle);
	}, window);

	Event.addEventListener('scroll.infinite', () => {
		const doc = document.documentElement;
		const offset = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
		handleVideo(offset, streamState.articles[streamState.currentArticleIndex], streamState.currentArticleIndex, streamState.header);
		if (streamState.articles[streamState.currentArticleIndex].serverData.animatedBody) {
			scrollAnimation(streamState.articles[streamState.currentArticleIndex].imagesInBody, false);
		}
	});

	if (streamState.articles[0].article.templateType === 'topline') {
		toplineAnimation();
	}

	Event.addEventListener('scroll.throttled', throttle(() => checkArticleScrollDepth(streamState), 300));
});

export default streamState;
