/**
 * Adds class wrapped into badge elements that wrap into a new line
 * @param {Object} wrapper Badges wrapper containing the badges
 */
const wrappedBadges = (wrapper, separator) => {
	[...wrapper.children].forEach((el) => {
		if (el.offsetTop > separator.offsetTop && el.previousElementSibling?.classList.contains('vert-pipe')) {
			el.previousElementSibling.classList.add('wrapped');
			el.classList.add('wrapped');
		}
	});
};

const observer = new ResizeObserver((entries, separator) => {
	(entries || []).forEach((entry) => {
		wrappedBadges(entry.target, separator);
	});
});

document.addEventListener('DOMContentLoaded', () => {
	const badges = document.querySelector('.top-label-wrapper');
	const dotSeparator = document.querySelectorAll('.vert-pipe.dot')[0];

	observer.observe(badges, dotSeparator);
	wrappedBadges(badges, dotSeparator);
});
