import { insertAd, insertMedianet } from '../../shared/adInsertion';
import { getTracking } from '../../shared/clientConfigService';
import Observable from '../../shared/Observable';
import { runAfterMeterVerified } from '../../shared/paywallUtils';

const speedBumpMap = {
	A: '416701230',
	B: '441235036',
	C: '178441252',
	D: '656893154',
	E: '763466047',
	'scroll-A': '204688714',
	'scroll-B': '671111417',
	'scroll-C': '816403463',
	'scroll-D': '666485071',
	'scroll-E': '561604663',
};

const medianetMap = {
	A: '388170426',
	B: '815850078',
	C: '668437154',
	D: '266942747',
	E: '564014248',
	'scroll-A': '783746142',
	'scroll-B': '521246729',
	'scroll-C': '348513773',
	'scroll-D': '453423336',
	'scroll-E': '825555218',
};

const mobileStickyAdSizesNoHero = [[300, 50], [320, 50], [1, 1], 'fluid'];
let sizeMappingNoHero;

/**
 * Toggle the mobile sticky ad to only display when there is no relative video in the article or
 * when ad experience is set to none or when scrolling back into an the first
 * article with a hero ad.
 * @param {HTMLElement} stickyAd The ad fixed to the bottom of the article.
 * @param {Object} currentArticle The article currently in view.
 * @param {Number} currentArticleIndex The current index of the article in view.
 */
function toggleMobileStickyDisplay({ stickyAd, currentArticle, currentArticleIndex }) {
	if (!stickyAd) {
		return;
	}
	const heroAdTakeover = document.querySelector('.fbs-ad--top-wrapper--takeover');

	if ((Object.keys(currentArticle.serverData.relativeVideo || {}).length && !currentArticle.article.templateType.includes('premium'))
		|| currentArticle.serverData.adExperience === 'none'
		|| (currentArticleIndex === 0 && heroAdTakeover)) {
		if (!stickyAd.parentElement.classList.contains('hidden')) {
			stickyAd.parentElement.classList.add('hidden');
		}
	} else if (stickyAd.parentElement.classList.contains('hidden')) {
		stickyAd.parentElement.classList.remove('hidden');
	}
}
const toggleMobileStickyDisplayObservable = new Observable();
toggleMobileStickyDisplayObservable.subscribe(toggleMobileStickyDisplay);

/**
 * Rewrites the ad size of the mobile ad position to exclude the sizes for the Hero Ad on article that are not in the first position.
 */
function removeMobileHeroParams() {
	if (window.fbsads) {
		if (!sizeMappingNoHero) {
			sizeMappingNoHero = window.fbsads.googleTagProxy.googletag.sizeMapping().addSize([0, 0], mobileStickyAdSizesNoHero).build();
		}

		const mobileAdSlot = window.fbsads.googleTagProxy.googletag.pubads().getSlots().find((ad) => ad.getSlotElementId() === 'mobile-sticky');
		mobileAdSlot.defineSizeMapping(sizeMappingNoHero);
	}
}

/**
 * Loads Ads that are in view.
 * @param {Object} state Global stream state containing values needed across various files.
 * @param {HTMLElement[]} ads The ads of the current article.
 * @param {Number} offset The offset of the client from the top of the page.
 */
function displayAds(state, ads, offset) {
	const {
		currentArticleIndex,
		articles,
	} = state;

	for (let i = 0; i < ads.length; i++) {
		const ad = ads[i];

		// Display the ad about a viewport and half away from the top of the page.
		// Sometimes the offset on the ad is 0 when the page is laying out - Wait until it becomes a real number before loading
		if ((articles[currentArticleIndex].adsToPreload-- > 0
			|| (ad.offsetTop !== 0 && ad.offsetTop - (offset + (window.innerHeight * 1.5)) <= 0))
			&& window.fbsads) {
			window.fbsads.googleTagProxy.googletag.cmd.push(() => {
				ad.display();
			});
			ads.splice(i, 1);
			i--;
		} else {
			break;
		}
	}
}

/**
 * Fetches bids through our medianet ads service.
 * @param {Object} state Global stream state containing values needed across various files.
 */
function handleBidPreload(state) {
	state.articles[state.currentArticleIndex].preloadedNextBids = true;
	if ((window.fbsads || {}).medianetService) {
		window.fbsads.medianetService.prefetchBids();
	}
}

const insertStickyAdObservable = new Observable();
insertStickyAdObservable.subscribe(() => {
	insertAd('mobile', document, 'mobile-sticky');
});

/**
 * Updates the sticky ad in the article stream when ever we are scrolling between articles
 * @param {HTMLElement} stickyAd the stickyAd that is already in the stream and should be destroyed
 */
function updateStickyAd(stickyAd) {
	if (stickyAd) {
		stickyAd.remove();
	}
	insertStickyAdObservable.notify();
}

/**
 * Allows the sticky ad to initialize or refresh when needed.
 * @param {Object} state Global stream state containing values needed across various files.
 */
function handleStickyAd(state) {
	const {
		articles,
		currentArticleIndex,
		stickyAd,
	} = state;

	// overscrolled into next article and this is not the initial loading of the first article, sticky ad exists - refresh
	if (!(currentArticleIndex === 0 && articles.length === 1) && stickyAd) {
		if (!(Object.keys(articles[currentArticleIndex].serverData.relativeVideo || {}).length && !articles[currentArticleIndex].article.templateType.includes('premium'))) {
			if (stickyAd.parentElement) {
				const button = stickyAd.parentElement.querySelector('button');
				if (button) {
					button.remove();
				}
			}
			updateStickyAd(stickyAd);
			state.stickyAd = document.querySelector('fbs-ad[position="mobile"]');
		}
		// overscrolled into next article, sticky ad does not exist - initialize
	} else if (currentArticleIndex > 0 && !stickyAd && !Object.keys(articles[currentArticleIndex].serverData.relativeVideo || {}).length && articles[currentArticleIndex].serverData.adExperience !== 'none') {
		updateStickyAd(stickyAd);
		state.stickyAd = document.querySelector('fbs-ad[position="mobile"]');
		state.stickyAd.parentElement.classList.remove('hidden');
	}
}

/**
 * Initializes ads on the current article.
 * @param {Object} state Global stream state containing values needed across various files.
 */
function initializeAds(state) {
	const { currentArticleIndex, bucket } = state;

	state.articles[currentArticleIndex].adsInitialized = true;

	const tracking = getTracking();
	let preloadCount = 0;
	if (!tracking.templateType.includes('premium')) {
		preloadCount += 3;
	}
	if (state.articles[currentArticleIndex].index === 0
		&& !['ad', 'insights', 'connoisseur'].includes(tracking.blogType)
		&& !tracking.isRetracted
		&& !state.articles[currentArticleIndex].serverData.isAdLight) {
		preloadCount++;
	}
	state.articles[currentArticleIndex].adsToPreload = preloadCount;

	insertMedianet(`speedbump-${currentArticleIndex}`, '300x350', speedBumpMap[`${currentArticleIndex > 0 ? 'scroll-' : ''}${bucket}`]);
	insertMedianet(`medianet-${currentArticleIndex}`, '300x500', medianetMap[`${currentArticleIndex > 0 ? 'scroll-' : ''}${bucket}`]);
}

/**
 * Takes care of ad logic as the user scrolls up and down the page.
 * @param {Object} state global stream state containing values needed across various files.
 * @param {Number} offset The scroll distance from the top of the page.
 */
function handleAds({ state, offset }) {
	const { ads, adsInitialized } = state.articles[state.currentArticleIndex];

	// first time an article was scrolled into
	if (!adsInitialized) {
		initializeAds(state);
	}

	displayAds(state, ads, offset);

	if (ads.length < 2 && !state.articles[state.currentArticleIndex].preloadedNextBids) {
		handleBidPreload(state);
	}
}

const handleAdsObservable = new Observable();
handleAdsObservable.subscribe(handleAds);

function pauseAdsUntilMeterVerified() {
	runAfterMeterVerified([toggleMobileStickyDisplayObservable, handleAdsObservable, insertStickyAdObservable]);
}

export {
	toggleMobileStickyDisplayObservable,
	removeMobileHeroParams,
	handleAdsObservable,
	pauseAdsUntilMeterVerified,
	handleStickyAd,
};
