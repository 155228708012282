import throttle from '../../shared/throttle';
import Event from '../../shared/event';

const topAdWrapper = document.querySelector('.fbs-ad--top-wrapper');
let haRibbon;
let scrollEl;

/**
 * Slides the hero ad ribbon in and out of view based on the users scroll depth
 */
function handleHeroScroll() {
	const headerHeight = document.querySelector('.logo-header-wrapper').clientHeight;
	const threshold = window.scrollY + headerHeight + 66;

	if (threshold > scrollEl.offsetTop) {
		haRibbon?.classList.add('ha__ribbon--slide-up');
	} else {
		haRibbon?.classList.remove('ha__ribbon--slide-up');
	}
}

/**
 * Initialize the hero ad scroll event and all its variables
 * we wait about one second for the ad and it's contents to come in.
 */
function setUpHeroScroll() {
	const offscreenInterval = setInterval(() => {
		if (((topAdWrapper || {}).classList || []).contains('fbs-ad--top-wrapper--offscreen')) {
			clearInterval(offscreenInterval);
			setTimeout(() => {
				haRibbon = document.querySelector('.ha__ribbon');
				scrollEl = document.querySelector('.ha__scroll-el');
				Event.addEventListener('scroll.hero', throttle(handleHeroScroll, 300));
			}, 1000);
		}
	}, 500);
}

export default setUpHeroScroll;
