// eslint-disable-next-line import/no-unresolved, import/extensions
import { template } from '../../../../../dist/templates/_mobile-article';
import { actionOpenWeb } from '../../shared/openWebUtils';
import {
	fetchPageviews,
	getUtmParams,
	insertPageViews,
	updateMetaForSimpleReach,
	updateMiniPublishDates,
	handleFooterAdLabel,
} from '../shared/articleUtilities';
import { setLiveTemplateMetrics } from '../shared/liveUpdateArticle';
import { updateAdParams, applyConfig } from '../../shared/adUtilities';
import checkStatus from '../../shared/checkStatus';
import { primeInviewEvent, virtualTrack } from '../../shared/tracking';
import { unstickVideo } from './stickyVideo';
import { handleAutoplayVideos, clearPaywallQueues } from '../../shared/paywallUtils';
import {
	toggleMobileStickyDisplayObservable,
	removeMobileHeroParams,
	handleAdsObservable,
	pauseAdsUntilMeterVerified,
	handleStickyAd,
} from './adsUtilities';
import toplineAnimation from '../shared/toplineAnimation';
import { updateGamzoneUserState } from '../../shared/clientConfigService';
import { initGrid, getGridState } from '../../shared/grid';
import {
	articleStoryNavHide,
	toggleMobileNavbarArrow,
} from '../shared/storyPackage';
import { updateAnchorNav } from '../shared/anchorNav';
import truncateContribBiosForArticle from '../shared/truncateContribBiosForArticle';
import { isMobile } from '../../shared/is-mobile';
import followingOverlay from '../shared/followingOverlay';
import addOpenwebScript from '../shared/openweb';

let trending;
const startFromBrandvoice = ((window.forbes['simple-site'] || {}).tracking || {}).blogType === 'ad';
let loadingArticle = false;
let loadingIndicator = (document.getElementsByClassName('spinner-html') || [])[0];
const isCriOS = navigator.userAgent.indexOf('CriOS') > -1;
const failedArticles = {};
const subnavOuter = document.querySelector('.container__subnav--outer');

const socialIcons = [...document.querySelectorAll('.social-share')].map((social) => (
	{
		iconElement: social.querySelector('.social-link'),
		labelElement: social.querySelector('.social-link-label'),
		initialLink: social.querySelector('a').href,
		name: social.className.split('--')[1],
	}
));

// Check if we are in bertie preview mode or not to prevent tracking on preview.
const { isPreview = false } = window.forbes['simple-site'];

function getFirstAuthorName() {
	const authorNameMeta = document.querySelector('meta[property="article:author"]');
	return authorNameMeta ? authorNameMeta.getAttribute('content') : '';
}

/**
 * Sets the document title based on the article title.
 * @param {String} title The title of the article to set the document to.
 */
function setTitle(title) {
	try {
		document.getElementsByTagName('title')[0].innerHTML = title.replace('<', '&lt;').replace('>', '&gt;').replace(' & ', ' &amp; ');
	} catch (e) {
		// do nothing
	}
	document.title = title;
}

/**
 * Formats the title for brandvoice articles.
 * @param {String} bvTitle The title of the brandvoice article.
 */
function sanitizeBvTitle(bvTitle) {
	return bvTitle ? `${bvTitle.replace(/[Vv]oice$/, '').replace('|', '')} BrandVoice: ` : '';
}

/**
 * Returns the correctly formatted article title.
 *
 * @param {Object} article The clean article created from the API response
 * @returns {String} Page title
 */
function getArticleTitle(article) {
	if (!(article || {}).title) {
		return '';
	}

	if (article.blogType === 'ad') {
		return `${sanitizeBvTitle((article.authorGroup.publication || {}).name)}${article.title}`;
	}

	if (article.blogType === 'comm') {
		return `Council Post: ${article.title}`;
	}

	if (article.blogType === 'insights') {
		return `Forbes Insights: ${article.title}`;
	}

	if (article.blogType === 'connoisseur') {
		return `${(article.authorGroup.primaryAuthor || {}).blogName} Connoisseur: ${article.title}`;
	}

	return article.title;
}

/**
 * Updates the social icon links with the current article in infinite scroll.
 *
 * @param {Object[]} socialIconsMap An array of the social icons and their label elements.
 * @param {Object} article The current article in view.
 * @param {Number} articleIndex The current article index.
 */
function updateSharing(socialIconsMap, article, articleIndex) {
	socialIconsMap.forEach((social) => {
		const articleUri = articleIndex === 0 ? social.initialLink : article.social[social.name];
		social.iconElement.href = articleUri;
		social.labelElement.href = articleUri;
	});
}

function updateCurrentPageClass(index) {
	const currentPages = Array.from(document.querySelectorAll('.current-page'));
	const currentArticle = document.querySelector(`#article-stream-${index}`);
	const ribbonAd = document.querySelector('.ribbon-mobile-ad-wrapper .fbs-ad--mobile-wrapper');

	currentPages.forEach((page) => page.classList.remove('current-page'));
	currentArticle?.classList.add('current-page');

	if (window.forbes['simple-site']?.tracking?.contentPaywall === 'premium') {
		ribbonAd?.classList.add('hidden');
	} else {
		ribbonAd?.classList.remove('hidden');
	}
}

/**
 * Adds style tag to the head of an article
 *
 * @param {string} styleString The string of styles to be the innerHTML
 */
const addStylesTag = (styleString = '') => {
	const styles = document.createElement('style');
	styles.innerHTML = styleString;
	document.getElementsByTagName('head')[0].appendChild(styles);
};

/**
 * Loads the next article into the DOM.
 * @param {Object[]} articles The current articles in the stream that have or will be loaded.
 * @param {Object} response The data returned from requesting the next article in the stream.
 * @param {Number} index Current stream position of the next article in the stream.
 * @param {String} ntvContentLineItemId The line item id of the ntvcontentm ad that loads into the first mobile article page.
 */
function insertArticle(articles, response, index, ntvContentLineItemId) {
	let element;
	if (index > 0) {
		// Update the zone before inserting the next article so the ads that bootstrap on the next article have the corresponding zone
		/* eslint-disable-next-line no-underscore-dangle */
		if ((window.fbsads || {})._config) {
			/* eslint-disable-next-line no-underscore-dangle */
			window.fbsads._config.ad_unit_path = updateGamzoneUserState(`/${response.serverData.adInventory}/fdcmobile/${response.serverData.adZone}`);
		}
		const wrapper = document.createRange().createContextualFragment(template(response));
		element = wrapper.firstChild;
		loadingIndicator.parentElement.insertBefore(element, loadingIndicator);
		truncateContribBiosForArticle(index, response.serverData.isPremiumTemplateType);
	} else {
		element = document.getElementsByTagName('article')[0];
	}

	handleFooterAdLabel(index);

	// premium articles come with extra styling to append to the head
	if (response.article.inflatedEmbedStyles) {
		addStylesTag(response.article.inflatedEmbedStyles);
	}

	// add element in view tracking for story package and anchor link navbar elements
	if (response.article.templateType === 'premium') {
		primeInviewEvent(element.querySelector('.story-package__nav-wrapper'), 'Story Package Nav - In-View');
		const navbarToggleElement = element.querySelector('.story-package__title-data');

		if (navbarToggleElement) {
			navbarToggleElement.addEventListener('click', () => {
				toggleMobileNavbarArrow(navbarToggleElement);
			});
		}
		primeInviewEvent(element.querySelector('.anchor-navbar__wrapper'), 'Premium Anchor Link - In-View');
	}

	// we don't want to fetch pageviews in preview mode or if templateType is "finds"
	if ((response.serverData || {}).shouldGetPageViews && !window.forbes['simple-site'].isPreview) {
		fetchPageviews((response.article || {}).naturalId)
			.then((pageviews) => insertPageViews(pageviews, element));
	} else if (response.serverData.tracking.templateType === 'live') {
		setLiveTemplateMetrics(element);
	}

	const serverData = {
		...response.serverData,
		tracking: {
			...response.serverData.tracking,
			index,
			startFromBrandvoice,
		},
	};

	// passing in false to getGridState for no ads
	if (serverData.shouldGetMoreFromBlock) {
		initGrid(element, getGridState(false));
	}

	// very important to set this here for the mobile scheduler logic
	if (index === 1) {
		const ntvAd = document.querySelector('#ntv-contentm a[data-href], .ntv-contentm .link[data-href]');

		// make sure the uris match because article insertion and ntv ad rendering could be off sync when scrolling fast
		if (ntvAd && response.article.uri.split('forbes.com')[1].indexOf(ntvAd.getAttribute('data-href').split('forbes.com')[1]) > -1) {
			serverData.tracking.ntvContentLineItemId = ntvContentLineItemId;
		}
	}

	updateMiniPublishDates(response.article.templateType, element);
	articles[index] = {
		article: response.article,
		headerLegaleseData: response.headerLegaleseData,
		serverData,
		path: response.article.uri.replace(/https?:\/\/([^/])*/gi, ''),
		index,
		element,
		ads: Array.from(element.querySelectorAll('fbs-ad')),
		adsInitialized: false,
		tracked: false,
		imagesInBody: element.querySelectorAll('.animatable'),
	};

	if (index === 0) {
		pauseAdsUntilMeterVerified();
		handleAutoplayVideos(articles[index]);
	}

	if (serverData.showOpenWeb) {
		addOpenwebScript(element);
		actionOpenWeb(index, serverData.articleId);
	}
	followingOverlay(articles[index].element, true);
}

/**
 * Calls the api to get the next article data in the stream.
 * @param {Number} index The Stream position of the article we are going to fetch.
 * @param {Object} state Global stream state containing values needed across various files.
 */
function fetchArticle(index, state) {
	if (trending[index - 1] && (trending[index - 1].uri || '').indexOf('.forbes.com') > -1) {
		loadingArticle = true;

		fetch(`${trending[index - 1].uri.split('forbes.com')[1]}?malcolm=${state.bucket}&api=true&streamIndex=${index}`, {
			headers: {
				// Stupid varnish
				'user-agent': 'Mozilla/5.0 (iPhone; CPU iPhone OS 11_0 like Mac OS X) AppleWebKit/604.1.38 (KHTML, like Gecko) Version/11.0 Mobile/15A372 Safari/604.1',
			},
		})
			.then((response) => checkStatus(response))
			.then((res) => res.json())
			.then((res) => {
				insertArticle(state.articles, res, index, state.ntvContentLineItemId);
				loadingArticle = false;

				if (trending.length <= index) {
					loadingIndicator.remove();
					loadingIndicator = null;
				}
			})
			.catch((e) => {
				console.error('Failed to load next article!', e);

				// If the server replied with any statuscode, then the request actually made it to the server
				// Only try twice per URL maximum that actually made it to the backend
				if ((e.response || {}).status && [502, 503, 504].indexOf(e.response.status) === -1) {
					failedArticles[trending[index - 1].uri] = (failedArticles[trending[index - 1].uri] || 0) + 1;

					if (failedArticles[trending[index - 1].uri] >= 2) {
						trending.shift();
					}
				}

				loadingArticle = false;
			});
	}
}

/**
 *  Checks if the any articles in the article state match the current path.
 * @param {Object} state Global stream state containing values needed across various files.
 * @param {String} path The current path in the address bar.
 */
function findArticleIndexByPath(state, path) {
	return state.articles.find((article) => article.path === path);
}

/**
 * Manages the history api state to make sure the correct url is showing in the browser. Fxires tracking as well.
 * @param {Object} state Global stream state containing values needed across various files.
 * @param {Number} newIndex The new index we are overscrolling or underscrolling to.
 */
function handleHistory(state, newIndex) {
	window.forbes['simple-site'] = state.articles[newIndex].serverData;
	const { title } = state.articles[newIndex].article;
	updateMetaForSimpleReach((state.articles[newIndex].article.authorGroup.primaryAuthor || {}).name, state.articles[newIndex].article.uri);
	const updatedUri = `${state.articles[newIndex].article.uri.split('forbes.com')[1]}?${getUtmParams()}`;

	// underscrolled into previous article
	if (newIndex < state.currentArticleIndex) {
		/**
		 * Currently, iPhones running Chrome cause a page refresh when calling history.back().
		 * So as a current solution, let's just push the article that's scrolled into onto the history stack.
		 * This prevents the browser refresh, but adds a lot more back button presses to
		 * get back to whatever page before hitting Forbes.
		 * This is all assuming people scroll through the articles like they are going mad.
		 */
		if (isCriOS) {
			window.history.pushState({
				...window.history.state,
				ss: undefined,
			}, title, updatedUri);
		// handle things the better way for non iPhone Chrome users.
		} else {
			window.history.back();
		}
		if (!isPreview) {
			virtualTrack(false);
		}
	// overscrolled into next article - non IPhone Chrome
	} else if (state.articles[newIndex].historied && !isCriOS) {
		window.history.forward();
		if (!isPreview) {
			virtualTrack(false);
		}
	// overscrolled into next article - Iphone Chrome
	} else {
		window.history.pushState({
			...window.history.state,
			ss: undefined,
		}, title, updatedUri);
		state.articles[newIndex].historied = true;
		const articleTitle = getArticleTitle(state.articles[newIndex].article);
		setTitle(articleTitle);
		if (!isPreview) {
			virtualTrack(true);
		}
	}
}

/**
 * Updates all the page values for the next article we are switching to.
 * @param {Object} state Global stream state containing values needed across various files.
 * @param {Number} newIndex The new position in the stream we are switching to.
 */
function changeArticle(state, newIndex) {
	const {
		articles,
		currentArticleIndex: oldIndex,
		header,
		stickyAd,
	} = state;

	// @TODO history stuff
	handleHistory(state, newIndex);
	state.currentArticleIndex = newIndex;

	clearPaywallQueues();
	pauseAdsUntilMeterVerified();
	handleAutoplayVideos(articles[newIndex]);

	// unstick the video on the previous article if it had an autoplaying video
	if (articles[oldIndex].currentAutoplayingVideo) {
		unstickVideo(articles[oldIndex], header);
	}

	// If next article is topline, start the animation
	if (articles[newIndex].article.templateType === 'topline') {
		toplineAnimation(newIndex);
	}

	// update all the various parts of the page with data from the new articles
	updateSharing(socialIcons, articles[newIndex].article, newIndex);
	updateCurrentPageClass(newIndex);
	updateAdParams();
	toggleMobileStickyDisplayObservable.notify({ stickyAd, currentArticle: articles[newIndex], currentArticleIndex: newIndex });

	// should probably leave a comment to explain why this is happening
	if (stickyAd && newIndex > 0) {
		removeMobileHeroParams(newIndex);
	}

	// show and hide story package modules if necessary
	articleStoryNavHide(articles[newIndex].element, 'remove');
	updateAnchorNav(articles[newIndex].element, 'remove');

	if (oldIndex < newIndex) {
		articleStoryNavHide(articles[oldIndex].element, 'add');
		updateAnchorNav(articles[oldIndex].element, 'add');
	}

	/**
	 * When an article with a sticky video at the top is rendered, we do not render the sticky Ad so the position=mobile
	 * slot is not defined yet. When scrolling into the next article, this boolean allows us to know whether or not to modify the
	 * mobileAdsConfig in applyConfig such that the mobile position has the [7, 1] ad size removed
	 * for all articles in the stream after the initial article.
	 */
	const initializedNoStickyAd = newIndex > 0 && !stickyAd;
	applyConfig(false, initializedNoStickyAd);

	const doc = document.documentElement;
	handleAdsObservable.notify({ state, offset: (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0) });
	handleStickyAd(state);

	/* eslint-disable-next-line no-use-before-define */
	checkArticleScrollDepth(state);
}

/**
 * Toggle Visibility of subnav on scrolling to the next article in the stream
 * @param {String} style to show or hide the subnav
 */
function toggleVettedSubnav(style) {
	if (isMobile && subnavOuter) {
		subnavOuter.style.display = style;
	}
}

/**
 * Scroll handler to know when to display ads, change articles, and fetch the next article in the stream.
 * @param {Object} state Global stream state containing values needed across various files.
 */
function checkArticleScrollDepth(state) {
	const doc = document.documentElement;
	const offset = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
	const { currentArticleIndex, articles } = state;
	const currentArticle = articles[currentArticleIndex];
	const { element } = currentArticle;
	const nextElement = (articles[currentArticleIndex + 1] || {}).element || loadingIndicator;

	handleAdsObservable.notify({ state, offset });

	// Overscrolled into next article - Switch
	if (nextElement && offset > nextElement.offsetTop - (window.innerHeight * 0.75)) {
		// Only change if it is loaded
		if (articles[currentArticleIndex + 1]) {
			changeArticle(state, currentArticleIndex + 1);
			toggleVettedSubnav('none');
		}
	// Underscrolled into previous article - Switch
	} else if (currentArticleIndex > 0 && offset < element.offsetTop - (window.innerHeight * 0.75)) {
		changeArticle(state, currentArticleIndex - 1);
		toggleVettedSubnav('block');
	}

	// Load next article if
	if (loadingIndicator
		&& !articles[currentArticleIndex + 1]
		&& !loadingArticle
		&& (offset - element.offsetTop) > (nextElement.offsetTop - element.offsetTop) * 0.25) {
		// If this is the first time through, we can set the trending stories.
		if (!currentArticleIndex && !trending) {
			trending = ((window.forbes['simple-site'] || {}).topStories || []).filter((article) => article.uri);
		}
		fetchArticle(currentArticleIndex + 1, state);
	}
}

/**
 * Sends fuse targeting key values to any of the amp-brightcove players with the right ima3Tag Plugin.
 * @param {Promise} fusePromise Async request for fuse values based on client_id.  is made in shared/adsUtilities.js
 */
function initFusePostMessage(fusePromise) {
	window.addEventListener('message', (e) => {
		if (e.origin === 'https://players.brightcove.net' && e.data === 'ima3 ready' && fusePromise) {
			fusePromise.then((fuseData) => {
				if (!(fuseData || {}).kvs) {
					return;
				}

				const { bertieBadgeSlugs = '' } = window.forbes['simple-site'].tracking || {};
				const isDecisionMakerContent = bertieBadgeSlugs.indexOf('decisionmaker') > -1 || bertieBadgeSlugs.indexOf('decision-maker') > -1;
				const data = {};

				['hi', 'jt'].forEach((key) => {
					if (fuseData.kvs[key]) {
						data[key] = fuseData.kvs[key];
					}
				});

				if (isDecisionMakerContent && data.jt !== '1') {
					data.jt = '1.1';
				}

				data.type = 'passedTargeting';
				e.source.postMessage(JSON.stringify(data), e.origin);
			});
		}
	});
}

export {
	checkArticleScrollDepth,
	findArticleIndexByPath,
	getArticleTitle,
	getFirstAuthorName,
	initFusePostMessage,
	insertArticle,
	setTitle,
};
