import setUpHeroScroll from './heroAd';
import streamState from './streamService';

let lastArticle = -1;

/**
 * Shows all ads when insuring that there is no adblock on mobile
 */
function showAds() {
	const fbsAds = document.querySelectorAll('.article-mobile-ad');
	const speedbumpAds = document.querySelectorAll('.article-speedbump.ad-unit');
	const nativeAds = document.querySelectorAll('.article-native-ad');

	nativeAds.forEach((ad) => {
		ad.removeAttribute('hidden');
	});
	fbsAds.forEach((ad) => {
		ad.removeAttribute('hidden');
	});
	speedbumpAds.forEach((ad) => {
		ad.removeAttribute('hidden');
	});

	lastArticle = streamState.articles.length;
}

const minAdHeight = 40;
const fixedHeightSet = {};
const resizeObservers = {};

/**
 * Checks if new height is more than the previously set max height and if it is
 * locks the parent ad container to new max height
 * @param {Element} element DOM element to attach resize observer
 * @param {String} slot ad slot id from GPT
 * @param {Number} height height of the newly refreshed ad
 * */
function checkAndApplyFixedHeight(element, slot, height) {
	if (height > minAdHeight && (!fixedHeightSet[slot] || height > fixedHeightSet[slot])) {
		element.parentElement.style.minHeight = `${height}px`;
		fixedHeightSet[slot] = height;
	}
}

/**
 * Attaches resize observer to the given element.
 * When height is changed, calls the checkAndApplyFixedHeight with a new height of the element
 * @param {Element} element DOM element to attach resize observer
 * @param {String} slot ad slot id from GPT
 * */
function attachResizeObserver(element, slot) {
	if (resizeObservers[slot]) {
		return;
	}
	const resizeObj = new ResizeObserver((entries) => {
		const rect = entries[0].contentRect;
		checkAndApplyFixedHeight(element, slot, rect.height);
	});
	resizeObj.observe(element);
	resizeObservers[slot] = true;
}

// Listen to ad render to store line item ID for second article tracking data
document.addEventListener('fbs-ad-render', (event) => {
	const data = event.detail;
	if (data.slot) {
		const slotId = data.slot.getSlotElementId();
		if (slotId.includes('inline')) {
			// with this if check, we are detecting if the served ad is fluid or not
			// 1x1 is blocker ad size, so we need to exclude it
			// if it's not 1x1, sizes like 0x0, 2x1, etc. will be considered as fluid ads
			if (!(data.size[0] === 1 && data.size[1] === 1) && data.size[0] < 5 && data.size[1] < 5) {
				const element = document.getElementById(slotId);
				// if ad is fluid, lock the ad container to 320px unless it's already locked to bigger height
				checkAndApplyFixedHeight(element, slotId, Math.max(320, element.offsetHeight));
			}
		}
	}

	// When fbs-ad-render gets called that means no adblock exist and we should show all the ads
	// and only call this function once a new article is inserted
	if (lastArticle !== streamState.articles.length) {
		showAds();
	}

	const dataSize = (data || {}).size || [];
	// Promoted Template Ids are Fluid ad format ids
	const promotedTemplateIds = [11986934];
	const isPromoted = (dataSize[0] === 2 && dataSize[1] === 3) || promotedTemplateIds.includes(data.creativeTemplateId || '');
	if (isPromoted) {
		const nativeAd = document.querySelector('.article-native-ad').firstChild;
		nativeAd?.classList.add('native-ad');
	}

	if (dataSize[0] === 7) {
		setUpHeroScroll();
		return;
	}

	if (data.slot.getSlotElementId() !== 'ntv-contentm') {
		return;
	}
	streamState.ntvContentLineItemId = data.lineItemId;
});

// always lock inline ad containers to the max height of ad that has been served inside
// this prevents CLS when smaller ad serves but still allows shift to happen when bigger one does
document.addEventListener('fbs-ad-load', (event) => {
	const { detail } = event;
	let slot = '';
	if (detail.slot) {
		slot = detail.slot.getSlotElementId();
		const element = document.getElementById(slot);
		if (slot.includes('inline') && !detail.isEmpty) {
			checkAndApplyFixedHeight(element, slot, element.offsetHeight);
			attachResizeObserver(element, slot);
		}
	}
});
