/**
 * Remove the sticky class from the header and the sticky autoplaying video. Also unset the autoplay video.
 * @param {Object} currentArticle The current article in view
 * @param {HTMLElement} header The nav of the page
 */
function unstickVideo(currentArticle, header) {
	currentArticle.currentAutoplayingVideo.el.parentNode.parentNode.parentNode.classList.remove('subnav-sticky');
	currentArticle.currentAutoplayingVideo.el.parentNode.parentNode.parentNode.classList.remove('sticky');
	currentArticle.currentAutoplayingVideo = null;
	header?.classList.remove('hidden');
}

function videoEnded(currentArticle) {
	if (!currentArticle.serverData.relativeVideos.length) {
		if (currentArticle.currentAutoplayingVideo) {
			unstickVideo(currentArticle);
		}
		return;
	}

	currentArticle.videos[0].setAttribute('video-id', currentArticle.serverData.relativeVideos.shift().videoId);
}

/**
 * Sets up the close button for the autoplay sticky video in the current article
 * @param {Object} currentArticle The current article in view
 * @param {HTMLElement} header The nav of the page
 * @param {HTMLElement} video The current video element that is going to be sticky
 */
function initClose(currentArticle, header, video) {
	const close = video.close || document.createElement('span');
	close.classList.add('close');
	close.innerHTML = 'x';
	video.close = close;

	if (close.listener) {
		close.removeEventListener('click', close.listener);
	}

	close.listener = () => {
		currentArticle.videos.splice(currentArticle.videos.indexOf(video), 1);
		currentArticle.currentAutoplayingVideo.el.pause();
		unstickVideo(currentArticle, header);
	};

	close.addEventListener('click', close.listener);
	const ampVideoWrapper = document.querySelector('.amp-bc-wrapper');
	ampVideoWrapper.appendChild(close);

	// TODO: ADSS-79 Experimenting if CLS scores could be improved without heavily impacting Ad revenue by disabling sticky behaviour
	// JIRA: https://forbesmedia.atlassian.net/browse/ADSS-79
	if (window.forbes['simple-site'].tracking.author !== 'Derek Saul') {
		ampVideoWrapper.classList.add('sticky');
	}

	const isSubnavPresent = document.querySelector('.container__subnav--outer');
	if (isSubnavPresent) {
		ampVideoWrapper.classList.add('subnav-sticky');
	}
}

/**
 * Scroll handler for the sticky autoplaying video in the article
 * @param {Number} offset scroll position from the top of the article
 * @param {Object} currentArticle The current article in view
 * @param {Number} currentArticleIndex The position in the stream
 * @param {HTMLElement} header The nav of the page
 */
function handleVideo(offset, currentArticle, currentArticleIndex, header) {
	if (!currentArticle.videos) {
		currentArticle.videos = [...currentArticle.element.querySelectorAll('fbs-video')].filter((el) => el.autoplay).slice(0, 1);
		if (currentArticle.videos.length) {
			currentArticle.videos[0].addEventListener('ended', () => videoEnded(currentArticle));
		}
	}

	if (currentArticle.currentAutoplayingVideo) {
		const videoOffsetTop = currentArticle.currentAutoplayingVideo.el.parentNode.parentNode.parentNode.parentNode.offsetTop;
		const videoOffsetHeight = currentArticle.currentAutoplayingVideo.el.parentNode.parentNode.parentNode.offsetHeight;

		if (((videoOffsetHeight / 2) + videoOffsetTop) - header.offsetHeight >= offset) {
			unstickVideo(currentArticle, header);
		}
	}

	if (!currentArticle.currentAutoplayingVideo && currentArticle.videos.length) {
		currentArticle.videos.forEach((el) => {
			const subnavHeight = document.querySelector('.container__subnav--outer')?.offsetHeight || 0;
			const videoOffsetTop = el.parentNode.parentNode.parentNode.parentNode.offsetTop - subnavHeight;
			if (offset >= videoOffsetTop - header.offsetHeight && el.playing) {
				currentArticle.currentAutoplayingVideo = {
					el,
					sourceArticleIndex: currentArticleIndex,
				};

				initClose(currentArticle, header, el);
			}
		});
	}
}

export {
	handleVideo,
	unstickVideo,
};
